import styles from './footer.module.css';
import React from 'react';
import perseoMedia from '../../../src/assets/Header/perseo-logo-white.svg'
import { Fade, Modal, Box, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import aire from '../../../src/assets/Header/Logo_Grupo_aire.png';
import jsonDataES from '../../assets/Languages/es.json'
import { useTranslation } from 'react-i18next';
import jsonDataEN from '../../assets/Languages/en.json';

const Footer = () => {

    const [openAviso, setOpenAviso] = React.useState(false);
    const { t } = useTranslation();
    const handleOpenAviso = () => {
        setOpenAviso(true);
    };

    const handleCloseAviso = () => {
        setOpenAviso(false);
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <footer >
            <nav className={styles.nav}>
                <section className={styles.section}>
                    <h3>{t('footer.withYou')}</h3>
                    <p>{t('footer.withYouInfo1')}</p>

                    <p>{t('footer.withYouInfo2')}</p>
                </section>
                <section style={{display:'flex', gap:'10%'}}>
                    <section className={styles.section}>
                        <h3>{t('footer.direction')}</h3>
                        <p>{t('footer.street')}</p>

                        <p>{t('footer.town')}</p>

                        <p>{t('footer.postal')}</p>
                    </section>
                    <section className={styles.section}>
                        <h3>{t('footer.contactFooter')}</h3>
                        <a href="Contact" style={{color:'white'}}>{t('footer.talkWithUs')}</a>
                    </section>
                </section>
                
            </nav>
            <hr />
            <section className={styles.avisos}>
                <a onClick={handleOpenAviso}>{t('footer.addLegal')}</a>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"

                    open={openAviso}
                    onClose={handleCloseAviso}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openAviso}>
                        <div className={styles['modal-container']}>
                            <h4 className={styles['m-modal-header__title text-color-primary']}>{t('footer.addLegal')}</h4>

                            <a onClick={handleClose} className={styles['icon-close']} data-bs-dismiss="modal" aria-label="Close"></a>
                            <div className={styles['modal-body modal-text']}>

                                <div className={styles['modal-info']}>

                                    <h5 >{t('footer.modalLegal.whoWeAre')}</h5>

                                    <p>{t('footer.modalLegal.perseo')}</p>

                                    <p>{t('footer.modalLegal.merca')}</p>
                                    
                                    <p>{t('footer.modalLegal.legal')}</p>

                                    <h5 >{t('footer.modalLegal.useConditions')}</h5>

                                    <p>{t('footer.modalLegal.useConditions1')}</p>

                                    <p>{t('footer.modalLegal.useConditions2')}</p>

                                    <h5 className={styles['font-size-base font-weight-bold text-color-base-grey-medium-dark']}>{t('footer.modalLegal.useConditions4')}</h5>

                                    <p>{t('footer.modalLegal.useConditions5')}</p>


                                    <h5 className={styles['font-size-base font-weight-bold text-color-base-grey-medium-dark']}>{t('footer.modalLegal.guarantee')}</h5>

                                    <p>{t('footer.modalLegal.guarantee1')}</p>

                                    <p>{t('footer.modalLegal.guarantee2')}</p>

                                    <p>{t('footer.modalLegal.guarantee3')}</p>

                                    <p>{t('footer.modalLegal.guarantee4')}</p>

                                    <h5 className={styles['font-size-base font-weight-bold text-color-base-grey-medium-dark']}>{t('footer.modalLegal.jurisdiction')}</h5>

                                    <p>{t('footer.modalLegal.jurisdiction1')}</p>
                                </div>
                            </div>
                            <div className={styles['modal-footer']}>
                                <a onClick={handleCloseAviso} className={styles['btn']} data-bs-dismiss="modal"><span>{t('footer.modalLegal.accept')}</span></a>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <a  onClick={handleOpen}>{t('footer.cookiesPolitical')}</a>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"

                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={styles['modal-container']}>
                            <h4 className={styles['m-modal-header__title text-color-primary']}>{t('footer.modalCookies.cookieTitle')}</h4>

                            <a onClick={handleClose} className={styles['icon-close']} data-bs-dismiss="modal" aria-label="Close"></a>
                            <div className={styles['modal-body modal-text']}>

                                <div className={styles['modal-info']}>

                                    <h5 >{t('footer.modalCookies.whatIs')}</h5>
                                </div>

                                <h5 className={styles['cookie_text']}>{t('footer.modalCookies.before')}</h5>

                                <p >{t('footer.modalCookies.cookiesPolitics')}</p>

                                <h5 className={styles['cookie_text']}>{t('footer.modalCookies.info')}</h5>

                                <h5 className={styles['cookie_subtitle']}>{t('footer.modalCookies.wCookie')}</h5>

                                <p>{t('footer.modalCookies.wc1')}</p>

                                <p>{t('footer.modalCookies.wc2')}</p>

                                <p>{t('footer.modalCookies.wc3')}</p>

                                <p>{t('footer.modalCookies.wc4')}</p>

                                <h5 className={styles['cookie_subtitle']}>{t('footer.modalCookies.cookiesExist')}</h5>

                                <p>{t('footer.modalCookies.property.p1')}</p>

                                <ul>

                                <li>

                                    <p>{t('footer.modalCookies.property.p2')}</p>

                                </li>

                                <li>

                                    <p>{t('footer.modalCookies.property.p3')}</p>

                                </li>

                                </ul>

                                <p>{t('footer.modalCookies.class')}</p>

                                <ul>

                                <li>

                                    <p>{t('footer.modalCookies.classT.classT1')}</p>

                                </li>

                                <li>

                                    <p>{t('footer.modalCookies.classT.classT2')}</p>

                                </li>

                                <li>

                                    <p>{t('footer.modalCookies.classT.classT3')}</p>

                                </li>

                                <li>

                                    <p>{t('footer.modalCookies.classT.classT4')}</p>

                                </li>

                                </ul>

                                <h5 className={styles['cookie_subtitle']}>{t('footer.modalCookies.webCookies')}</h5>

                                <p>{t('footer.modalCookies.webCookies_p')}</p>
                            </div>
                            <div className={styles['modal-footer']}>
                                <a onClick={handleClose} className={styles['btn']} data-bs-dismiss="modal"><span>{t('footer.modalLegal.accept')}</span></a>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <a href="https://grupoaire.es/">
                    <img src={aire} className={styles['img']} />
                </a>
            </section>

        </footer>
    )
}

export default Footer