// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/MarkOT.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/MarkOT-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n    --color-black: #101010;\n    --font-size-small: 14px;\n    --font-size-medium: 16px;\n    --font-size-large: 18px;\n}\na, a:-webkit-any-link {\n    text-decoration: none !important;\n}\n\n@font-face {\n    font-family: 'markOT';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n    /* Puedes agregar más formatos aquí si es necesario */\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'markOT-bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n    /* Puedes agregar más formatos aquí si es necesario */\n    font-weight: normal;\n    font-style: normal;\n}", "",{"version":3,"sources":["webpack://./src/styles/globals.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;AAC3B;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;IACrB,+DAAkD;IAClD,qDAAqD;IACrD,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,0BAA0B;IAC1B,+DAAuD;IACvD,qDAAqD;IACrD,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[":root {\n    --color-black: #101010;\n    --font-size-small: 14px;\n    --font-size-medium: 16px;\n    --font-size-large: 18px;\n}\na, a:-webkit-any-link {\n    text-decoration: none !important;\n}\n\n@font-face {\n    font-family: 'markOT';\n    src: url('../fonts/MarkOT.otf') format('opentype');\n    /* Puedes agregar más formatos aquí si es necesario */\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face {\n    font-family: 'markOT-bold';\n    src: url('../fonts/MarkOT-Bold.otf') format('opentype');\n    /* Puedes agregar más formatos aquí si es necesario */\n    font-weight: normal;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
