import React, {useState} from 'react';
import styles from './accordion.module.css';
import { useMediaQuery } from '@mui/material';
import { theme } from "../../themes/theme"
//import HPropsAccordion from './accordion.interface';


interface AccordionItem {
    title: string;
    subtitle: string;
    content: string;
    subcontent?: string
}

interface HPropsAccordion {
    items: AccordionItem[];
}

const Accordion: React.FC<HPropsAccordion> = ({items})=>{

    //Este apartado sirve a modo de saber cual es el estado de 
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [activeMobil, setActiveMobil] = useState<number | null>(null);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.md}px)`)
    console.log(isMobile)
    const handleMouseEnter = (index:number) =>{
        setActiveIndex(index);
    };
    const handleMouseLeave = () =>{
        setActiveIndex(null);
    };

    //aqui se genera un mapa para obtener la información de cada apartado del accordion
    return (
        <ul className={styles['accordion-list']}>
          {items.map((item, index) => (
            <li className={`${styles["accordion-element"]} ${styles[`item-${index}`]}  
            ${activeIndex === index ? styles.active : ""} ${activeMobil === index ? styles.activeMobil : ""} data-id={item-${index}}`} 
            onMouseEnter={() => setActiveIndex(index)} 
            onMouseLeave={() => setActiveIndex(null)}
            key={`list-item-${index}`}
            >
              <div className={styles["section-title"]}>
                {item.title}
              </div>
              <div className={styles["section-subtitle"]}>
                {item.subtitle}
              </div>
              <div className={styles["section-content"]}>
                {item.content}
                <br />
                <br />
                {item.subcontent}
              </div>
            </li>
          ))}
        </ul>
      );
};
export default Accordion;

/*
            <li
              key={index}
              //para determinar si se debe agregar la clase "active" al elemento del acordeón o no
              className={styles["accordion-item"]}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <h3 className={styles["accordion-title"]}>{item.title}</h3>
              {activeIndex === index ? (
                <p className={styles["accordion-content"]}>{item.content}</p>
              ) : <p className={styles["accordion-subtitle"]}>{item.subtitle}</p>}
            </li>
*/