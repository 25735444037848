// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".language_buttons__SEC30{\n    display: flex;\n    align-items: center;\n    color: darkgrey;\n    font-family: 'markOT-bold', sans-serif;\n    font-size: 14px;\n    gap: 5px;\n}\n.language_buttons__SEC30 a{\n    cursor: pointer;\n}\n\n@media (min-width: 467px) and (max-width: 767px) {\n    .language_buttons__SEC30{\n        flex-direction: column;\n        margin-left: 1em;\n    }\n    .language_buttons__SEC30 p{\n        margin:0;\n    }\n}\n@media (min-width: 200px) and (max-width: 467px) { \n    .language_buttons__SEC30{\n        flex-direction: column;\n        margin-left: 3em;\n    }\n    .language_buttons__SEC30 p{\n        margin:0;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/context/language.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,sCAAsC;IACtC,eAAe;IACf,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;QACtB,gBAAgB;IACpB;IACA;QACI,QAAQ;IACZ;AACJ;AACA;IACI;QACI,sBAAsB;QACtB,gBAAgB;IACpB;IACA;QACI,QAAQ;IACZ;AACJ","sourcesContent":[".buttons{\n    display: flex;\n    align-items: center;\n    color: darkgrey;\n    font-family: 'markOT-bold', sans-serif;\n    font-size: 14px;\n    gap: 5px;\n}\n.buttons a{\n    cursor: pointer;\n}\n\n@media (min-width: 467px) and (max-width: 767px) {\n    .buttons{\n        flex-direction: column;\n        margin-left: 1em;\n    }\n    .buttons p{\n        margin:0;\n    }\n}\n@media (min-width: 200px) and (max-width: 467px) { \n    .buttons{\n        flex-direction: column;\n        margin-left: 3em;\n    }\n    .buttons p{\n        margin:0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "language_buttons__SEC30"
};
export default ___CSS_LOADER_EXPORT___;
