import styled from 'styled-components'

export const Wrapper = styled.section`
  font-family: 'markOT', sans-serif;
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3%;
  z-index: 1;
  margin-top:7em;
  
  @media (min-width: 300px) and (max-width: 767px) {
    display: grid;
    grid-template-columns: 0% 100%;
    margin-right: 3%;
  }
`
export const Img = styled.img`
  width:30%;
  heigth:80%;
`

export const ContainerTitles = styled.div`
  text-align: center;
    font-size: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  margin: auto;
  gap: 30px;
  max-width: 1200px;

`

export const Content = styled.div`
  flex-direction: column;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-right:2%;
  width: 100%;
  padding-top:1em;
`

export const Up = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  @media (min-width: 300px) and (max-width: 767px) {
    width: 95%;
  }
`

export const Flex = styled.div`
  display: rows;
    font-size: 12px;
`
export const Div = styled.div`
  display: flex;
  column-gap:5%;
  margin-bottom:2em;
  font-family: 'markOT', sans-serif;
`

export const Type = styled.div`
  display: flex;
  margin-bottom:2em;
  margin-left: 25%
`
export const Desc = styled.div`
margin-bottom:2em;
`

export const Input = styled.input`
  width: 80%;
  padding: 15px;
  border-radius: 5px;
  margin-top:2em;
  padding-left:3em;
  border-color: #000;
  border:5px;
`

export const TextArea = styled.textarea`
  width: 90%;
  height: 150%;
  padding: 15px;
  border-radius: 5px;
  margin-top:2em;
  margin-bottom:3em;
`

export const TextContainer = styled.div`
  
  width: 90%;
  margin-bottom: 1.5em;
  font-family: 'markOT', sans-serif;
  font-size: 14px;
  line-height: 2;
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
`
export const Check = styled.input`
  margin-left:0%
`
export const Input_Type = styled.input`
  margin-left:4%;
  width: 80%;
  padding: 15px;
  border-radius: 5px;
  margin-top:2em;
  padding-left:3em;
`

export const Number = styled.span`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: rgb(230, 0, 124, 0.3);
  color: #e6007c;
  display: flex;
  font-size: 17px;
  justify-content: center;
  align-items: center;
`

export const Line = styled.span`
  width: calc(120% - 50px);
  left: calc(50% + 50px);
  top: 24px;
  position: absolute;
  display: none;
  border-style: dashed;
  border-color: #e6007c;
  border-top: 1px;

`

export const Text = styled.p`
  color: rgb(162 162 168);
  font-size: 20px;
`
export const Par = styled.p`
  font-size: 16px;
`

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  gap: 20px;
`

export const Send = styled.button`
  border-radius: 5px;
    padding: 12px
px
 137px;
    color: rgb(142, 142, 142);
    cursor: pointer;
    background: rgb(221, 221, 221);
    width: 150p;
        padding: 10px;
    padding-left: 60px;
    padding-right: 60px;
    margin: auto;
    font-size: 16px;
  
  &:hover {
    text-decoration: none;
  }
`
export const Message = styled.p`
  font-size: 16px;
  font-family: 'markOT', sans-serif;
`

export const Close = styled.a`
position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1050;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  opacity: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 0.15rem;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -0.0625rem;
    background: #2e2c39;
  }

  &::before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

  /* Transiciones */
  transition: all 0.3s ease;

  /* Transiciones para enfoque y hover */
  &:focus,
  &:hover {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  &:focus::before,
  &:hover::before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }
  &:focus::after,
  &:hover::after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

`

export const ModalDiv = styled.div`
display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    align-items: center;
    justify-content: left;
    z-index: 9999;
    overflow-y: auto;
    max-height: 800px;
    max-width: 700px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 20px;
    top: 40%;
    border-style: solid;
    border-color: chartreuse;
    border-radius: 5px;
    font-size: 0.875rem!important
`

export const ModalContent = styled.div`
  font-size: 16px;
`