// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import esTranslations from '../../assets/Languages/es.json';
import enTranslations from '../../assets/Languages/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    es: { translation: esTranslations },
  },
  lng: 'en', // Idioma predeterminado
  fallbackLng: 'es', // Idioma de respaldo si el idioma no está disponible
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
