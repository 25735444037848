import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './language.module.css'

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  return (
    <div className={styles.buttons}>
      <a onClick={() => changeLanguage('en')}style={{ color: selectedLanguage === 'en' ? 'white' : 'darkgrey' }}>EN</a>
      <p>/</p>
      <a onClick={() => changeLanguage('es')}style={{ color: selectedLanguage === 'es' ? 'white' : 'darkgrey' }}>ES</a>
    </div>
  );
};

export default LanguageSelector;
