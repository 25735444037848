import Header from '../../components/Headers/Header'
import styles from './home_page.module.css'
import '../../styles/globals.css';
import Footer from '../../components/Footer/Footer'
import Accordeon from '../../components/Accordion/accordion'
import corre from '../../../src/assets/Home/PRUEBA.png'
import nav from '../../../src/assets/Home/nav.png'
import { title } from 'process'
import Cards from '../../components/Cards/cards'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    const accordeonItems = [
        {
            title: t('home.accordeon.perseoTV'),
            subtitle:t('home.accordeon.perseoTV_subtitle'),
            content:t('home.accordeon.perseoTV_content'),
            subcontent:t('home.accordeon.perseoTV_subcontent')
        },
        {
            title:t('home.accordeon.perseoOTT'),
            subtitle:t('home.accordeon.perseoOTT_subtitle'),
            content:t('home.accordeon.perseoOTT_content'),
            subcontent:t('home.accordeon.perseoOTT_subcontent')
        },
        {
            title: t('home.accordeon.perseoDisplays'),
            subtitle:t('home.accordeon.perseoDisplays_subtitle'),
            content:t('home.accordeon.perseoDisplays_content'),
            subcontent: t('home.accordeon.perseoDisplays_subcontent')
        },
        {
            title:t('home.accordeon.perseoTransport'),
            subtitle:t('home.accordeon.perseoTransport_subtitle'),
            content: t('home.accordeon.perseoTransport_content')
        },
        {
            title:t('home.accordeon.perseoTransco'),
            subtitle:t('home.accordeon.perseoTransco_subtitle'),
            content: t('home.accordeon.perseoTransco_content')
        },
        {
            title:t('home.accordeon.perseoCDN'),
            subtitle:t('home.accordeon.perseoCDN_subtitle'),
            content: t('home.accordeon.perseoCDN_content')
        }
    ]
    const cardsItems = [
        {
            title: t('home.card.multi'),
            subtitle:t('home.card.multi_sub'),
            content: t('home.card.multi_content'),
            img: nav
        },
        {
            title:t('home.card.management'),
            subtitle: t('home.card.management_subtitle'),
            content: t('home.card.management_content'),
            subcontent:[{
                content1: t('home.card.management_subcontent.content1'),
                content2: t('home.card.management_subcontent.content2'),
                content3: t('home.card.management_subcontent.content3'),
                content4: t('home.card.management_subcontent.content4'),
            }]
        },
        {
            title: t('home.card.distri'),
            subtitle:t('home.card.distri_subtitle'),
            content:'',
            subcontent:[{
                content1:t('home.card.distri_subcontent.content1')
            }]
        },
        {
            title: t('home.card.api'),
            subtitle: t('home.card.api_sub'),
            content:'',
            subcontent:[{
                content1: t('home.card.api_subcontent.content1')
            }]
        }
    ]
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
      }, []);
    return(
        <>
            <Header/>
            <section className={styles['grid']}>
                <section className={styles['ini']}>
                    <img src={corre} alt="" />
                    <div>
                        <h2>
                            {t('home.title')}
                        </h2>
                        <h4>{t('home.subtitle')}</h4>
                    </div>
                    
                </section>
                <section className={styles['textTools']}>
                    <h5>{t('home.textTools_title')}</h5>
                    <section>
                        <p>{t('home.textTools_subtitle')}</p>
                        <p>{t('home.textTools_subtitle_2')}</p>
                    </section>
                </section>
                <section className={styles['dinam']}>
                    <h2>{t('home.dinam')}</h2>
                        <Accordeon items={accordeonItems} />
                    
                </section>
                <section className={styles['card']}>
                    <h2>{t('home.card_sub')}</h2>
                    <Cards items = {cardsItems}/>
                </section>
                <section className={styles['disp_back']}>
                    <h2>{t('home.disp_back')}</h2>
                    <section className={styles['backinfo']}>
                        <div>
                            <PaymentsOutlinedIcon/>
                            <h3>{t('home.disp_back')}</h3>
                            <p>{t('home.disp_back_1')}</p>
                        </div>
                        <div>
                            <PlayCircleOutlineIcon/>
                            <h3>{t('home.disp_back_2')}</h3>
                            <p>{t('home.disp_back_3')}</p>
                            <p>{t('home.disp_back_4')}</p>
                        </div>
                        <div>
                            <ZoomOutMapIcon/>
                            <h3>{t('home.disp_back_5')}</h3>
                            <p>{t('home.disp_back_6')}</p>
                        </div>
                    </section>
                    
                </section>
                <section className={styles['contact']}>
                    <h2>{t('home.contact_Home')}</h2>
                    <a href="/Contact">{t('home.contact_sub')}</a>
                    <IconButton onClick={()=> {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                        <KeyboardArrowUpIcon/>
                    </IconButton>
                </section>
                
            </section>
            <Footer></Footer>
        </>
        
    )
}
export default Home