import React, { useEffect, useState, useCallback } from 'react'
import {
  Wrapper,
  ModalDiv, Message, ModalContent, Close,
  ContainerTitles,
  Type,
  Img, Up, Par, Form,
  TextContainer, ConfirmContainer, Send, Check,
  Div,
  Flex
} from './contact.module'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import Header from '../../components/Headers/Header'
import Footer from '../../components/Footer/Footer'
import { Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])

  const [token, setToken] = useState('')
  
  const verifyRecaptchaCallback = useCallback((token: React.SetStateAction<string>) => {
    setToken(token)
  }, [])

  const [modalMessage, setModalMessage] = useState('');

  const { t } = useTranslation();
  const [datos, setDatos] = useState({
    name: '',
    company: '',
    email: '',
    mobile: '',
    type: '',
    description: '',
    comercial: ''
  })

  const submit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const data = new URLSearchParams()
    data.append('name', datos.name)
    data.append('company', datos.company)
    data.append('email', datos.email)
    data.append('mobile', datos.mobile)
    data.append('type', datos.type)
    data.append('description', datos.description)
    datos.comercial = isChecked ? 'Sí' : 'No'
    data.append('comercial', datos.comercial)
    data.append('g-recaptcha-response', token)
    const response = await fetch('https://perseomedia.com/mail/contact.php', {
      method: 'POST',
      body: data,
    });
    try {
      if (response.ok) {
        // Si la respuesta del servidor es exitosa (status 200), muestra el modal
        const json = await response.json();

        // Aquí puedes establecer un estado en React para mostrar el modal
        // Por ejemplo:
        handleOpen();
        const message = t('contact.modal');
        setModalMessage(message);
      } else {
        // Maneja los casos en los que la respuesta no es exitosa
        console.error('Error en la respuesta del servidor:', response.status);
      }
    } catch (error) {
      // Maneja los errores de la solicitud
      console.error('Error al enviar el formulario:', error);
    }

  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event: { target: { name: any, value: any } }) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })
  }

  //para saber si el checkbox esta seleccionado

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Invertimos el valor actual del estado
  };

  return (
    <>
      <Header title="Header Title" />
      <Wrapper>
        <Img src="https://estaticos02.perseo.tv/Perseo/estatico/web-media/fondo_contact.jpg" alt="fondo_contact" />
        <Flex>
          <ContainerTitles>
            <h2>{t('contact.titleForm')}</h2>
            <Par>
              {t('contact.descForm')}
            </Par>
          </ContainerTitles>
          <Form onSubmit={submit}>
            <Flex>
              <Up>
                <Div>
                  <TextField id="name"
                    label={t('contact.nameSurname')} name="name" onChange={handleInputChange}
                    style={{ width: '50%' }} required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField id="company" label={t('contact.company')}
                    name="company" onChange={handleInputChange}
                    style={{ width: '50%' }} required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <BusinessCenterIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Div>
                <Div>
                  <TextField
                    id="email"
                    label={t('contact.email')}
                    name="email"
                    onChange={handleInputChange}
                    required type='email'
                    style={{ width: '50%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <MailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField id="mobil" label={t('contact.mobile')} name="mobile"
                    onChange={handleInputChange} style={{ width: '50%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Div>

              </Up>
              <Type>
                <TextField id="type"
                  label={t('contact.typePlataform')} name="type"
                  onChange={handleInputChange} style={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <HomeRepairServiceIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Type>
              <Div>
                <TextField id="description" label={t('contact.description')}
                  name="description" onChange={handleInputChange} multiline rows={1} style={{ width: '91%' }} required />
              </Div>
            </Flex>
            <div>
              <TextContainer>
                {t('contact.infoForm')}
              </TextContainer>
              <ConfirmContainer>
                <span><Check type={'checkbox'} required />{t('contact.checkPriv')}</span>
                <span><Check type={'checkbox'} name="comercial" checked={isChecked}
                  onChange={handleCheckboxChange} />
                  {t('contact.checkSendInfo')}</span>
                <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
                <Send type={'submit'}>{t('contact.send')}</Send>
              </ConfirmContainer>
            </div>
          </Form>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"

            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <ModalDiv>
                <ModalContent>
                  <Message>{modalMessage}</Message>
                  <Message>{t('contact.mComercial')}</Message>
                  <Close onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></Close>
                </ModalContent>
              </ModalDiv>
            </Fade>
          </Modal>
        </Flex>
      </Wrapper>
      <Footer />
    </>
  )
}

export default Contact
