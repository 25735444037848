import Header from '../../components/Headers/Header'
import styles from './perseotv.module.css'
import perseoTV from '../../../src/assets/PerseoTv/PERSEO_TV_COLOR.svg'
import disp from '../../../src/assets/PerseoTv/dispositivos.png'
import apple from '../../../src/assets/PerseoTv/logo_apple.png'
import playstore from '../../../src/assets/PerseoTv/logo_google.png'

const PerseoTV = () => {
    return(
        <>
        <Header/>
            <section className={styles['title']}>
                <nav className={styles['lft']}>
                    <img src={perseoTV} alt="perseoTV" />
                    <h4>La innovación de lo tradicional</h4>
                    <nav>
                        <p>
                        Plataforma de televisión cloud configurable y gestionable. 
                        </p>
                        <p>
                            Ofrece a tus clientes una experiencia en streaming de canales en directo 
                            y video bajo demanda compatible con una amplia gama de dispositivos.
                        </p>
                    </nav>
                    
                </nav>
                <nav className={styles['rgh']}>
                    <img src={disp} alt="dispositivos" />
                    <div className={styles['enlaces']}>
                        <p>
                            Televisión en directo en todos tus dispositivos
                        </p>
                        <a href="https://apps.apple.com/es/app/perseo-tv/id6446682749"><img src={apple} alt="logo_apple" /></a>
                        <a href=""><img src={playstore} alt="logo_google" /> </a>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default PerseoTV