import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        main: '#e6008a'
      },
    },
    breakpoints: {
      values: {
        xs: 0, 
        sm: 480, 
        md: 768,
        lg: 1024, 
        xl: 1280,
      }
    }
})
