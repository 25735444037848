import React,{ useState } from 'react';
import '../../styles/globals.css';
import { IHeaderDefault, IItemsHeader } from './headers.interface';
import styles from './headers.module.css';
import perseoMedia from '../../../src/assets/Header/Logo_perseo_media.png'
import camBlue from '../../../src/assets/Header/perseoMedia_Movil.png'
import { List, ListItem} from '@mui/material';
import { Link, useLocation} from 'react-router-dom';
import jsonDataES from '../../assets/Languages/es.json';
import jsonDataEN from '../../assets/Languages/en.json';
import LanguageSelector from '../context/languageSelector'
import { useTranslation } from 'react-i18next';


export default function Header(props: IHeaderDefault) {
   
    const { t } = useTranslation();
    const location = useLocation();
    const isOnContactPage = location.pathname === '/Contact';
    
    const items: IItemsHeader[] = [
        /*
        { link: '', label: t('header.product') , submenu: [
            { link: '/PerseoTV', label: t('header.perseoTv') },
            { link: './App', label: t('header.plataforms')},
            { link: './App', label: t('header.easyFronts')},
            { link: './App', label: t('header.transport') },
            { link: './App', label: t('header.transco')},
            { link: './App', label: t('header.cdn')}
        ], noheader: true},
        {
            link: '', label: t('header.industries'), noheader:true, submenu: [
                { link: '/Create', label: t('header.create') },
                { link: './Companies', label: t('header.companies')},
                { link: './operators', label: t('header.operator')},
            ]
        },
        { link: './App', label: t('header.infoUs'), submenu: [
            { link: '/AboutUs', label: t('header.aboutUs')},
            { link: '/Contact', label: t('header.contactHeader')}
        ], noheader:true},
        */
        { link: isOnContactPage ? undefined : '/Contact', label: t('header.talk')}
    ]

    return (
        <header className={styles.header}>
            <nav className={styles.logo}>
                <a href="/" >
                    <img className={styles.logoG} src={perseoMedia} alt="LogoGrande" />
                    <img className={styles.logoP} src={camBlue} alt="LogoPeque" />
                </a>
            </nav>
            <List component="nav" className={styles.navbar}>
            
                {items.length > 0 && items.map((item, index) => {
                    return (
                        <ListItem key={`menu-item-${index}`} 
                        className={`${styles['menu-item']} ${item.noheader ? styles['menu-itemNoHead'] : ''}`}
                        >
                            {item.link ? (
                                <Link to={item.link}>{item.label}</Link>
                                ) : (
                                <Link to={''}>{item.label}</Link>
                                )}
                            {item.submenu && (
                                <List className={styles['submenu-container']}>
                                    {item.submenu.length > 0 && item.submenu.map((el, i) => {
                                        return (
                                            <ListItem key={`submenu-item-${i}-${el.label.toLowerCase()}`}className={styles['subinfo']}>
                                                
                                                <Link to={el.link}>
                                                    {el.label}
                                                </Link>
                                                
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            )}
                        </ListItem>
                    )
                })}
                <div className={styles['divLan']}>
                    <LanguageSelector/>
                </div>
            </List>
        </header>
    );
}
