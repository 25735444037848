import { Routes, Route, BrowserRouter} from "react-router-dom";
import './App.css';
import Contact from "./page/Contact/Contact";
import PerseoTV from "./page/PerseoTV/PerseoTV";
import HomePage from "./page/Home/Home";
import './styles/globals.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/context/i18n';
import { ThemeProvider } from "styled-components";
import { theme } from "./themes/theme";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useState } from "react";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleReCaptchaProvider reCaptchaKey='6LfmuSQoAAAAAO7n0n0y3jKeOnoYQQzB4qbRK0P_'>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/PerseoTV' element={<PerseoTV/>}/>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </I18nextProvider>
  );
}

export default App;
