import React, {useState} from 'react';
import styles from './cards.module.css';
import nav from '../../../src/assets/Home/nav.png'
import { url } from 'inspector';
import { URL } from 'url';
//import HPropsAccordion from './accordion.interface';

interface subcontent{
  content1?: string;
  content2?: string;
  content3?: string;
  content4?: string;
}

interface CardsItem {
    title: string;
    subtitle?: string;
    content?: string;
    subcontent?: subcontent[];
    img?: string;
}

interface HPropsCards{
    items: CardsItem[];
}
const Cards: React.FC<HPropsCards> = ({items})=>{

    //Este apartado sirve a modo de saber cual es el estado de 
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleMouseEnter = (index:number) =>{
        setActiveIndex(index);
    };
    const handleMouseLeave = () =>{
        setActiveIndex(null);
    };

    

    //aqui se genera un mapa para obtener la información de cada apartado del card
    return (
        <ul className={styles['card-list']}>
          {items.map((item, index) => (
            <li 
              className={`${styles["card-element"]}  ${activeIndex === index ? styles.active : ""} data-id={item-${index}}`} 
              onMouseEnter={() => setActiveIndex(index)} 
              onMouseLeave={() => setActiveIndex(null)}
              key={`list-item-${index}`}
            >
              <div className={`${styles['card-container']} ${styles[`item-${index}`]}`}>
                <h3 className={styles["section-title"]}>
                  {item.title}
                </h3>
                <div className={styles["section-subtitle"]}>
                  {item.subtitle}
                </div>
                <div className={styles["section-content"]}>
                  <h3>{item.content}</h3>
                  {item.subcontent?.map((subcontent) => (
                    <div className={styles["section-subcontent"]} key={index}>
                      <p>{subcontent.content1}</p>
                      <p>{subcontent.content2}</p>
                      <p>{subcontent.content3}</p>
                      <p>{subcontent.content4}</p>
                    </div>
                  ))}
                  <img src={process.env.PUBLIC_URL + item.img} alt="" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      );
};
export default Cards;
